import React from "react";
import PrimaryButton from "../../../../../../Common/Buttons/PrimaryButton";
import Modal from "../../../../../../Common/Modal";

import styles from "./UploadTypologyModal.module.scss";
import { useMutation } from "@apollo/client";
import {
 Field, Form, Formik
} from "formik";
import * as yup from "yup";
import TextField from "../../../../../../Common/TextField";
import Select from "react-select";
import { CREATE_TYPOLOGY } from "../../../../../api/mutations";
import { GET_TYPOLOGIES } from "../../../../../api/queries";

const UploadTypologyModal = ({
 isModalOpen, setUploadTypologyModalOpen, categories
}) => {
  const initialValues = {
    categoryId: "",
    name: "",
  };

  const validationSchema = {
    categoryId: yup.string().required("Obbligatorio"),
    name: yup.string().required("Obbligatorio"),
  };
  const [addTypology] = useMutation(CREATE_TYPOLOGY, {
    refetchQueries: [GET_TYPOLOGIES],
    awaitRefetchQueries: true,
  });

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={() => {
        setUploadTypologyModalOpen(false);
      }}
    >
      <div className={styles["modal__upload"]}>
        <Formik
          initialValues={initialValues}
          validationSchema={yup.object().shape(validationSchema)}
          onSubmit={async values => {
            await addTypology({
              variables: {
                createTypologyInput: values,
              },
            });
            setUploadTypologyModalOpen(false);
          }}
        >
          {({
 errors, touched, setFieldValue
}) => {
            return (
              <Form>
                <div className={styles.modal__dropdown}>
                  <label
                    style={{
                      display: "inline-block",
                      marginBottom: "10px",
                    }}
                    htmlFor={"detailsCode"}
                  >
                    detailsCode
                  </label>
                  <Select
                    name="categoryId"
                    placeholder={"Seleziona Categoria"}
                    onChange={data => setFieldValue("categoryId", data.value)}
                    options={categories.map(item => ({ value: item.id, label: item.name }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {touched.categoryId && errors.categoryId && <div className={styles.error}>{errors.categoryId}</div>}
                </div>
                <Field
                  name="name"
                  id="name"
                  label="Nome Tipologia"
                  component={TextField}
                  type="text"
                  borderRadius="5px"
                  inputColor="white"
                />
                <PrimaryButton type="submit" fontSize="18px" text="Aggiungi Tipologia"
width="100%" />
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default UploadTypologyModal;
