import { createRef, useState } from "react";
import CheckIcon from "../../../../../../../assets/svg/check-icon.svg";
import CloseIcon from "../../../../../../../assets/svg/cross-icon.svg";
import PencilImg from "../../../../../../../assets/svg/pencil.svg";
import IconButton from "../../../../../../Common/Buttons/IconButton";
import PrimaryButton from "../../../../../../Common/Buttons/PrimaryButton";
import EditClientProfileForm from "../EditClientProfileForm";
import styles from "./UserProfile.module.scss";

const UserProfile = ({ user }) => {
  const [editProfileFormOpen, setEditProfileFormOpen] = useState(false);
  const submitFormRef = createRef();

  return (
    <div className={styles.profile__wrapper}>
      <div className={styles.profile__header}>
        <h2 className={styles.profile__title}>Info cliente</h2>
        {editProfileFormOpen ? (
          <div className={styles.actions__wrapper}>
            <PrimaryButton
              text="Salva modifica"
              leftIconSrc={CheckIcon}
              onClick={() => submitFormRef.current.handleSubmit()}
              fontSize="10px"
              height="25px"
              padding="6px 10px"
              borderRadius="5px"
            />
            <IconButton
              padding="0"
              onClick={() => {
                submitFormRef.current.resetForm();
                setEditProfileFormOpen(false);
              }}
              iconSrc={CloseIcon}
            />
          </div>
        ) : (
          <IconButton onClick={() => setEditProfileFormOpen(true)} backgroundColor="#5E239D" iconSrc={PencilImg} />
        )}
      </div>
      <div className={styles.profile__table}>
        <EditClientProfileForm
          user={user}
          ref={submitFormRef}
          editProfileFormOpen={editProfileFormOpen}
          setEditProfileFormOpen={setEditProfileFormOpen}
        />
      </div>
    </div>
  );
};

export default UserProfile;
