import React from "react";
import Modal from "../../../../../../Common/Modal";
import { useMutation } from "@apollo/client";
import { DISABLE_USER } from "../../../../../api/mutations";
import { GET_USERS } from "../../../../../api/queries";
import ConfirmModal from "../../../../../../Common/ConfirmModal";
import { useNavigate } from "react-router";
import { RoutePath } from "../../../../../../../routers/constants";

const DeleteUserModal = ({
 isModalOpen, setDeleteUserModalOpen, userIdToDelete
}) => {
  const navigation = useNavigate();
  const [disableUser] = useMutation(DISABLE_USER, {
    refetchQueries: [GET_USERS],
    awaitRefetchQueries: true,
  });

  const confirmModal = async () => {
    await disableUser({
      variables: {
        userId: +userIdToDelete,
      },
    });
    setDeleteUserModalOpen(false);
    navigation(RoutePath.CLIENTS);
  };

  return (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={() => setDeleteUserModalOpen(false)}>
      <ConfirmModal setConfirmModalOpen={() => setDeleteUserModalOpen(false)} confirmModal={confirmModal} />
    </Modal>
  );
};

export default DeleteUserModal;
