import { createRef, useState } from 'react';
import BinIcon from '../../../../../assets/svg/bin.svg';
import CheckIcon from '../../../../../assets/svg/check-icon.svg';
import CloseIcon from '../../../../../assets/svg/cross-icon.svg';
import PencilImg from '../../../../../assets/svg/pencil.svg';
import IconButton from '../../../../Common/Buttons/IconButton';
import PrimaryButton from '../../../../Common/Buttons/PrimaryButton';
import EditEstablishmentsDetailsForm from './containers/EditEstablishmentsDetailsForm';
import styles from './EstablishmentsDetailsPage.module.scss';
import { useQuery } from "@apollo/client";
import DetailsEstablishmentGallery from "./containers/DetailsEstablishmentGallery";
import { GET_ESTABLISHMENT } from "../../../api/queries";
import FieldGallery from "./containers/FieldGallery";
import EstablishmentDisabilitiesAndFacilitiesAndTags from "./containers/EstablishmentDisabilitiesAndFacilitiesAndTags";
import DeleteEstablishmentModal from './containers/DeleteEstablishmentModal';

const EstablishmentDetailsPage = ({ id }) => {
	const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
	const currentEstablishment = useQuery(GET_ESTABLISHMENT, {
		variables: {
			establishmentInput: {
				id: Number(id),
			}
		}
	});

	const submitEditFormRef = createRef();
	const [editEstablishmentFormOpen, setEditEstablishmentFormOpen] = useState(false);
	return currentEstablishment?.data?.establishment ?
		<div className={styles.details__wrapper}>
			<div className={styles.house__info}>
				<div className={styles.info__header}>
					<h2 className={styles.profile__title}>Info Attività</h2>
					{editEstablishmentFormOpen ?
						(
							<div className={styles.actions__wrapper}>
								<PrimaryButton
									text='Salva modifica'
									leftIconSrc={CheckIcon}
									onClick={() => submitEditFormRef.current.handleSubmit()}
									fontSize="10px"
									height='25px'
									padding='6px 10px'
									borderRadius='5px'
								/>
								<IconButton
									padding='0'
									onClick={() => {
										submitEditFormRef.current.resetForm();
										setEditEstablishmentFormOpen(false);
									}}
									iconSrc={CloseIcon}
								/>
							</div>
						) :
						(
							<IconButton
								onClick={() => setEditEstablishmentFormOpen(true)}
								backgroundColor='#5E239D'
								iconSrc={PencilImg}
							/>
						)}
				</div>
				<EditEstablishmentsDetailsForm
					establishment={currentEstablishment?.data?.establishment}
					editEstablishmentFormOpen={editEstablishmentFormOpen}
					setEditEstablishmentFormOpen={setEditEstablishmentFormOpen}
					ref={submitEditFormRef} />
			</div>
			<EstablishmentDisabilitiesAndFacilitiesAndTags
				disabilities={currentEstablishment?.data?.establishment?.disabilities}
				facilities={currentEstablishment?.data?.establishment?.facilities}
				id={currentEstablishment?.data?.establishment?.id}
				categorie={
					currentEstablishment?.data?.establishment?.typologiesAndCategories?.map(
						element => element.category
					)?.map(categorie => categorie.id)}
			/>
			<FieldGallery
				gallery={currentEstablishment?.data?.establishment?.gallery}
				id={currentEstablishment?.data?.establishment?.id}
				coverId={currentEstablishment?.data?.establishment?.cover?.id}
			/>
			<DetailsEstablishmentGallery
				currentEstablishment={currentEstablishment?.data?.establishment}
				id={currentEstablishment?.data?.establishment?.id}
			/>
			<div className={styles['delete-btn__wrapper']}>
				<PrimaryButton
					width='auto'
					border='2px solid #FC440F'
					text='Elimina Attività'
					color='#FC440F'
					padding='8px'
					fontSize='18px'
					background='transparent'
					leftIconSrc={BinIcon}
					onClick={() => setIsConfirmDeleteModalOpen(true)}
				/>
			</div>
			<DeleteEstablishmentModal
				setDeleteEstablichmentModalOpen={setIsConfirmDeleteModalOpen}
				isModalOpen={isConfirmDeleteModalOpen}
				establishmentIdToDelete={id}
			/>
		</div> : null;
};

export default EstablishmentDetailsPage;
