import Collapsible from 'react-collapsible';
import Select from "react-select";
import React from "react";

function CategoriaDetails(
  {
    Sezione,
    Tags,
    setTagIdToDelete,
    setDeleteTagModalOpen
  }
) {
  const TagsSorted = Tags?.sort((a, b) => a.name.localeCompare(b.name));
  console.log(TagsSorted);
  return (
    <Collapsible key={Sezione.idsezione} tabIndex={Sezione.idsezione} trigger={Sezione.name}>
      <Select
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
          Menu: () => null,
          Input: () => null
        }}
        isMulti
        // isDisabled={!editEstablishmentFormOpen}
        name="categoryIds"
        placeholder={"Seleziona Sezione"}
        value={TagsSorted?.map(item => ({
          value: item.idTag,
          label: item.name
        }))}
        onChange={(_, { removedValue }) => {
          if (removedValue.value) {
            setTagIdToDelete(removedValue.value);
            setDeleteTagModalOpen(true);
          }
        }}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </Collapsible>
  );
}
export default CategoriaDetails;
