import clx from "classnames";
import {
 Field, Form, Formik
} from "formik";
import React, { forwardRef } from "react";
import * as yup from "yup";

import TextField from "../../../../../../Common/TextField";
import styles from "../UserProfile/UserProfile.module.scss";
import { useMutation } from "@apollo/client";
import { EDIT_USER } from "../../../../../api/mutations";
import { GET_USERS } from "../../../../../api/queries";
import Select from "react-select";

const EditClientProfileForm = forwardRef(({
 user, editProfileFormOpen, setEditProfileFormOpen
}, ref) => {
  const [editUser] = useMutation(EDIT_USER, {
    refetchQueries: [
      {
        query: GET_USERS,
        variables: {
          usersListInput: {
            limit: 100000000,
            offset: 0,
          },
        },
      },
    ],
    awaitRefetchQueries: true,
  });
  const initialValues = {
    id: user?.id,
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email,
    addressId: user?.address?.id || "",
    country: user?.address?.country || "",
    city: user?.address?.city || "",
    phone: user?.phone || "",
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string("Inserisci username").required("Il nome è obbligtorio"),
    lastName: yup.string("Inserisci username").required("il cognome è obbligatorio"),
    country: yup.string("Inserisci nazione"),
    city: yup.string("Inserisci città"),
    phone: yup.string("Inserisci numero telefonico"),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={ref}
      onSubmit={async values => {
        await editUser({
          variables: {
            editUserByAdminInput: {
              userId: values.id,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              address: {
                id: values.addressId,
                country: values.country,
                city: values.city,
              },
            },
          },
        });
        setEditProfileFormOpen(false);
      }}
    >
      {() => (
        <Form
          className={clx(styles["edit-profile__form"], {
            [styles["form__disabled"]]: !editProfileFormOpen,
          })}
        >
          <div>
            <Field
              name="firstName"
              id="firstName"
              label="Nome"
              component={TextField}
              type="text"
              borderRadius="5px"
              disabled={!editProfileFormOpen}
            />
            <Field
              name="lastName"
              id="lastName"
              label="Cognome"
              component={TextField}
              type="text"
              borderRadius="5px"
              disabled={!editProfileFormOpen}
            />
            <label
              style={{
                display: "inline-block",
                margin: "25px 0 10px 0",
              }}
              htmlFor={"categories"}
            >
              Disabilità
            </label>
            <Select
              isMulti
              name="disabilities"
              value={user?.disabilities.map(item => ({
                value: item.id,
                label: item.name,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div>
            <Field
              name="country"
              id="country"
              label="Nazione"
              component={TextField}
              type="text"
              borderRadius="5px"
              disabled={!editProfileFormOpen}
            />
            <Field
              name="city"
              id="city"
              label="Città"
              component={TextField}
              type="text"
              borderRadius="5px"
              disabled={!editProfileFormOpen}
            />
            <label
              style={{
                display: "inline-block",
                margin: "25px 0 10px 0",
              }}
              htmlFor={"categories"}
            >
              Ausili
            </label>
            <Select
              isMulti
              name="facilities"
              value={user?.facilities.map(item => ({
                value: item.id,
                label: item.name,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div>
            <Field name="email" id="email" label="Email"
component={TextField} type="email" borderRadius="5px"
disabled={true} />
            <Field
              name="phone"
              id="phone"
              label="N° Telefonico"
              component={TextField}
              type="text"
              borderRadius="5px"
              disabled={!editProfileFormOpen}
            />
            <Field
              name="phone"
              id="phone"
              label="Accompagnato?"
              component={TextField}
              value={user?.accompanied ? "Si" : "No"}
              borderRadius="5px"
              disabled={true}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
});

export default EditClientProfileForm;
