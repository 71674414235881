import { gql } from "@apollo/client";

export const GET_ME = gql`
  query me {
    me {
      id
      firstName
      lastName
      email
      dateBirth
      phone
      role
      avatar {
        id
        url
      }
      createdAt
      updatedAt
      isActive
      address {
        id
        country
        city
        street
        buildingNumber
        apartment
      }
      disabilities {
        id
        name
        isDisabled
      }
      facilities {
        id
        name
        isDisabled
      }
    }
  }
`;

export const GET_USERS = gql`
  query allUsers($usersListInput: UsersListInput!) {
    allUsers(usersListInput: $usersListInput) {
      id
      firstName
      lastName
      email
      dateBirth
      phone
      role
      avatar {
        id
        url
      }
      createdAt
      updatedAt
      isActive
      accompanied
      disabilities {
        id
        name
        isDisabled
      }
      facilities {
        id
        name
        isDisabled
      }
      enabled
    }
  }
`;

export const GET_USER = gql`
  query getUser($userId: Int!) {
    getUser(userId: $userId) {
      id
      firstName
      lastName
      email
      dateBirth
      phone
      role
      avatar {
        id
        url
      }
      createdAt
      updatedAt
      isActive
      enabled
      accompanied
      address {
        id
        country
        city
        street
        buildingNumber
        apartment
      }
      disabilities {
        id
        name
        isDisabled
      }
      facilities {
        id
        name
        isDisabled
      }
      favoriteList {
        id
        name
        phone
        address {
          id
          country
          city
          street
          buildingNumber
          apartment
        }
        typologiesAndCategories {
          typologyId
          name
          category {
            id
            name
            isDisabled
          }
          isDisabled
        }
      }
    }
  }
`;

export const GET_ESTABLISHMENTS = gql`
  query establishmentsList($establishmentListInput: EstablishmentListInput!) {
    establishmentsList(establishmentListInput: $establishmentListInput) {
      id
      name
      phone
      email
      website
      address {
        id
        country
        city
        street
        buildingNumber
        apartment
        lat
        lng
      
      }
      typologiesAndCategories {
        typologyId
        name
        category {
          id
          name
          isDisabled
        }
        isDisabled
      }
      createdAt
      updatedAt
      enabled
    }
  }
`;

export const GET_ESTABLISHMENT = gql`
  query establishment($establishmentInput: EstablishmentInput!) {
    establishment(establishmentInput: $establishmentInput) {
      id
      name
      phone
      email
      website
      description
      details {
        id
        code
        description
        title
        image {
          id
          url
        }
      }
      address {
        id
        country
        city
        street
        buildingNumber
        apartment
        lat
        lng
       
      }
      typologiesAndCategories {
        typologyId
        name
        category {
          id
          name
          isDisabled
        }
        isDisabled
      }
      disabilities {
        id
        name
        isDisabled
      }
      facilities {
        id
        name
        isDisabled
      }
      isFavorite
      cover {
        id
        url
      }
      gallery {
        id
        url
        title
        description
      }
      createdAt
      updatedAt
      enabled
    }
  }
`;



export const GET_CATEGORIES = gql`
  query establishmentCategories {
    establishmentCategories {
      id
      name
      url
      isDisabled
    }
  }
`;

export const GET_CITIES = gql`
  query establishmentCities {
    establishmentCities {
      lat,
      lng,
    }
  }
`;

export const GET_TYPOLOGIES = gql`
  query establishmentTypologies {
    establishmentTypologies {
      id
      name
      categoryId
      isDisabled
    }
  }
`;

export const GET_FACILITIES = gql`
  query facilities {
    facilities {
      id
      name
      url
      isDisabled
    }
  }
`;

export const GET_DISABILITIES = gql`
  query disabilities {
    disabilities {
      id
      name
      url
      isDisabled
    }
  }
`;
export const GET_SEZIONI = gql`
  query GetAllTagsAndFields {
    GetAllTagsAndFields(Filter: {}) {
      sezioni {
        name
        idSezione
      }
    }
  }
`;

export const GET_CATEGORIE = gql`
  query GetAllTagsAndFields($filter: filter!) {
    GetAllTagsAndFields(Filter: $filter) {
      categorie {
        idCategoria
        name
      }
    }
  }
`;
export const GET_TAGS = gql`
  query GetAllTagsAndFields($filter: filter!) {
    GetAllTagsAndFields(Filter: $filter) {
      tags {
        name
        idSezione
        idCategoria
        idTag
      }
    }
  }
`;
export const GET_TROVERA = gql`
  query GetAllTrovera($filter: Filter) {
    GetAllTrovera(Filter: $filter) {
      trovera {
        id
        name
      }
    }
  }
`;
export const GET_TROVERA_TO_ESTABLISHMENT = gql`
  query GetAllTrovera($filter: Filter) {
    GetAllTrovera(Filter: $filter) {
      troveraToEstablishment {
        id
        idEstablishment
        idTrovera
      }
    }
  }
`;
export const GET_TROVERA_ALL = gql`
  query GetAllTrovera($filter: Filter) {
    GetAllTrovera(Filter: $filter) {
      trovera {
        id
        name
      }
      troveraToEstablishment {
        id
        idEstablishment
        idTrovera
      }
    }
  }
`;
