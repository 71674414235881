import React from 'react';
import Modal from '../../../../../../Common/Modal';
import { useMutation } from "@apollo/client";
import { DISABLE_ESTABLISHMENT } from "../../../../../api/mutations";
import { GET_ESTABLISHMENTS } from "../../../../../api/queries";
import ConfirmModal from "../../../../../../Common/ConfirmModal";
import { useNavigate } from 'react-router';
import { RoutePath } from '../../../../../../../routers/constants';

const DeleteEstablishmentModal = ({
	isModalOpen,
	setDeleteEstablichmentModalOpen,
	establishmentIdToDelete
}) => {
	const navigation = useNavigate();
	const [disableEstablishment] = useMutation(DISABLE_ESTABLISHMENT, {
		refetchQueries: [GET_ESTABLISHMENTS],
		awaitRefetchQueries: true,
	});

	const confirmModal = async () => {
		await disableEstablishment({
			variables: {
				establishmentId: +establishmentIdToDelete
			}
		});
		setDeleteEstablichmentModalOpen(false);
		navigation(RoutePath.ESTABLISHMENTS);
	};

	return <Modal
		isModalOpen={isModalOpen}
		setIsModalOpen={() => setDeleteEstablichmentModalOpen(false)}
	>
		<ConfirmModal
			setConfirmModalOpen={() => setDeleteEstablichmentModalOpen(false)}
			confirmModal={confirmModal}
		/>
	</Modal>;
};

export default DeleteEstablishmentModal;
