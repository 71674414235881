import { memo, useMemo } from "react";

import DataTable from "../../../../Common/DataTable";
import UserDetailsPage from "../UserDetailsPage";
import { useQuery } from "@apollo/client";
import { GET_USERS } from "../../../api/queries";

const ClientsTable = memo(({ id }) => {
  const users = useQuery(GET_USERS, {
    variables: {
      usersListInput: {
        limit: 100000000,
        offset: 0,
      },
    },
  });

  const tableColumns = useMemo(
    () => [
      {
        name: "firstName",
        label: "Username",
        filter: false,
        sort: true,
      },
      {
        name: "accompanied",
        label: "Accompagnato",
        filter: false,
        sort: false,
        centered: true,
      },
      {
        name: "email",
        label: "Email",
        filter: false,
        sort: true,
      },
      {
        name: "createdAt",
        label: "Data registrazione",
        filter: false,
        sort: true,
      },
      {
        name: "phone",
        label: "Contatto",
        filter: true,
        sort: true,
        centered: true,
      },
      {
        name: "options",
        label: "Opzioni",
        filter: false,
        sort: false,
        centered: true,
      },
    ],
    []
  );

  return (
    <>
      {id ? (
        <UserDetailsPage id={id} />
      ) : (
        <DataTable
          tableTitle="Lista Utenti"
          tableData={users?.data?.allUsers?.map(item => {
            const date = new Date(item?.createdAt).toLocaleDateString("it-IT", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
            return {
              firstName: item.firstName,
              createdAt: date,
              email: item.email,
              phone: item.phone,
              id: item?.id,
              accompanied: item?.accompanied ? "Si" : "No",
            };
          })}
          tableName="Clienti"
          tableColumns={tableColumns}
        />
      )}
    </>
  );
});

export default ClientsTable;
