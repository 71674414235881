export const sideBarRoutes = [
  {
    path: 'clients',
    label: 'Utenti'
  },
  {
    path: 'establishments',
    label: 'Attività'
  },
  {
    path: 'categoriesTypologies',
    label: 'Categorie & Tipologie'
  },
  {
    path: 'tags',
    label: 'Gestione Tags'
  },
  {
    path: 'disabilitiesFacilities',
    label: 'Disabilità & Ausili'
  },
  {
    path: 'statistic',
    label: 'Statistiche'
  }

];
