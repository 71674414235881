import styles from "./CategoriesTypologies.module.scss";
import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import PlusIcon from "../../../../../assets/svg/plus.svg";

import CategoriaDetails from './CategoriaDetails';
import { GET_TROVERA } from "../../../api/queries";
import { Tablet } from "@material-ui/icons";
import Select from "react-select";
import UploadTroveraModal from "./containers/UploadTroveraModal";
import DeleteTroveraModal from "./containers/DeleteTroveraModal";


const TableTrovera = () => {
  const allTrovera = useQuery(GET_TROVERA, {
    variables: {
      filter: {
        getTrovera: true,
      }
    }
  });
  const troveraList = allTrovera?.data?.GetAllTrovera?.trovera;
  const trovera = troveraList?.map(item => item).sort((a, b) => a.name.localeCompare(b.name));
  const [uploadTagModalOpen, setUploadTagModalOpen] = useState(false);
  const [deleteTagModalOpen, setDeleteTagModalOpen] = useState(false);
  const [TroveraIdToDelete, setTroveraIdToDelete] = useState(false);

  return (

    <>
      <UploadTroveraModal
        isModalOpen={uploadTagModalOpen}
        setUploadTagModalOpen={setUploadTagModalOpen}
      />
      <DeleteTroveraModal
        setDeleteTagModalOpen={setDeleteTagModalOpen}
        isModalOpen={deleteTagModalOpen}
        TroveraIdToDelete={TroveraIdToDelete}
      />
      <div className={styles.categoriesAndTypologies__wrapper}>
        <div className={styles.actions}>
          <label htmlFor={"trovera"}>Cosa troverai</label>
          <PrimaryButton
            onClick={() => setUploadTagModalOpen(true)}
            text={`crea una tag cosa troverai`}
            leftIconSrc={PlusIcon}
            fontSize="10px"
            height='25px'
            width='fit-content'
            padding='6px 10px'
            borderRadius='10px'
            background='#078EA6'
          />
        </div>
        <Select
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
            Menu: () => null,
            Input: () => null
          }}
          isMulti
          // isDisabled={!editEstablishmentFormOpen}
          name="troveraIds"
          placeholder={"Seleziona tag cosa troverai"}
          value={trovera?.map(item => ({
            value: item?.id,
            label: item?.name
          }))}
          onChange={(_, { removedValue }) => {
            if (removedValue.value) {
              setTroveraIdToDelete(Number(removedValue.value));
              setDeleteTagModalOpen(true);
            }
          }}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>
    </>

  );
};

export default TableTrovera;


