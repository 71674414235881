import clx from "classnames";
import {
	Field, Form, Formik
} from "formik";
import React, { forwardRef } from "react";
import * as yup from "yup";
import TextField from "../../../../../../Common/TextField";
import styles from "./EditEstablishmentsDetailsForm.module.scss";
import Select from "react-select";
import { useMutation, useQuery } from "@apollo/client";

import {
	GET_CATEGORIES, GET_TYPOLOGIES, GET_ESTABLISHMENTS
} from "../../../../../api/queries";
import { EDIT_ESTABLISHMENT } from "../../../../../api/mutations";
import GoogleAutocomplete from "../../../../../../../components/GoogleAutocomplete";

const EditEstablishmentDetailsForm = forwardRef(({
	establishment, editEstablishmentFormOpen, setEditEstablishmentFormOpen
}, ref) => {
	const [editEstablishment] = useMutation(EDIT_ESTABLISHMENT, {
		refetchQueries: [
			{
				query: GET_ESTABLISHMENTS,
				variables: {
					establishmentListInput: {
						limit: 100000000,
						offset: 0,
					},
				},
			},
		],
		awaitRefetchQueries: true,
	});

	const categories = useQuery(GET_CATEGORIES, {});
	const typologies = useQuery(GET_TYPOLOGIES, {});
	const categoryOptions = categories?.data?.establishmentCategories
		?.map(item => ({ value: item.id, label: item.name }));

	const initialValues = {
		id: establishment?.id,
		name: establishment?.name || "",
		description: establishment?.description || "",
		categoryIds: establishment?.typologiesAndCategories.length ?
			establishment?.typologiesAndCategories.reduce(
				(acc, item) => (acc.includes(item.category.id) ? acc : [...acc, item.category.id]),
				[]
			) :
			[],
		typologyIds: establishment?.typologiesAndCategories.length ? establishment?.typologiesAndCategories
			.map(item => item.typologyId) : [],
		country: establishment?.address?.country || "",
		city: establishment?.address?.city || "",
		street: establishment?.address?.street || "",
		lat: establishment?.address?.lat || "",
		lng: establishment?.address?.lng || "",
		apartment: establishment?.address?.apartment || "",
		buildingNumber: establishment?.address?.buildingNumber || "",
		email: establishment?.email || "",
		website: establishment?.website || "",
		phone: establishment?.phone || "",
	};

	const validationSchema = yup.object().shape({
		email: yup.string("Inserisci la tua email").email("Inserisci un'email valida"),
		name: yup.string().required("Obbligatorio"),
		phone: yup
			.string()
			.min(3)
			.max(20)
			.matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, "Is not in correct format"),
		website: yup.string().url(),
		description: yup.string().required("Obbligatorio"),
		country: yup.string().required("Obbligatorio"),
		city: yup.string().required("Obbligatorio"),
		street: yup.string().required("Obbligatorio"),
		lat: yup.number().required("Obbligatorio").typeError("devi specificare un numero"),
		lng: yup.number().required("Obbligatorio").typeError("you must specify a number"),
		buildingNumber: yup.string().required("Obbligatorio"),
		apartment: yup.string(),
		typologyIds: yup.array().min(1, "Obbligatorio"),
	});

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={validationSchema}
			innerRef={ref}
			onSubmit={async values => {
				await editEstablishment({
					variables: {
						editEstablishmentInput: {
							id: values.id,
							name: values.name,
							phone: values.phone,
							website: values.website,
							email: values.email,
							// coverId: 0,
							description: values.description,
							address: {
								country: values.country,
								city: values.city,
								street: values.street,
								buildingNumber: values.buildingNumber,
								apartment: values.apartment,
								lat: Number(values.lat),
								lng: Number(values.lng),
							},
							typologyIds: values.typologyIds,
						},
					},
				});
				setEditEstablishmentFormOpen(false);
			}}
		>
			{({
				errors, touched, values, setFieldValue
			}) => (
				<Form
					className={clx(styles["edit-house__form"], {
						[styles["form__disabled"]]: !editEstablishmentFormOpen,
					})}
				>
					<div>
						<Field
							name="name"
							id="name"
							label="Titolo"
							component={TextField}
							type="text"
							borderRadius="5px"
							disabled={!editEstablishmentFormOpen}
						/>
						<label
							style={{
								display: "inline-block",
								margin: "25px 0 10px 0",
							}}
							htmlFor={"categories"}
						>
							Categorie
						</label>
						<Select
							isMulti
							isDisabled={!editEstablishmentFormOpen}
							name="categoryIds"
							placeholder={"Seleziona categorie"}
							value={categories?.data?.establishmentCategories
								?.filter(item => values.categoryIds.includes(item.id))
								.map(item => ({ value: item.id, label: item.name }))}
							onChange={data => {
								let categoryValues;
								if (data?.find(item => item.value === 'all')) {
									categoryValues = categoryOptions.map(item => item.value);
								} else {
									categoryValues = data.map(item => item.value);
								}

								setFieldValue("categoryIds", categoryValues);
								setFieldValue(
									"typologyIds",
									values.typologyIds.filter(item =>
										categoryValues.includes(
											typologies?.data?.establishmentTypologies
												?.find(typo => Number(typo.id) === Number(item))?.categoryId
										)
									)
								);
							}}
							options={categories?.data?.establishmentCategories &&
								[
									{ label: "Select All", value: "all" }, ...categoryOptions
								]}
							className="basic-multi-select"
							classNamePrefix="select"
						/>
						{touched.categoryIds && errors.categoryIds && <div className={styles.error}>{errors.categoryIds}</div>}
						<label
							style={{
								display: "inline-block",
								margin: "25px 0 10px 0",
							}}
							htmlFor={"typologies"}
						>
							Tipologie
						</label>
						<Select
							isMulti
							isDisabled={!editEstablishmentFormOpen}
							name="typologyIds"
							placeholder={"Seleziona tipologie"}
							onChange={data =>
								setFieldValue(
									"typologyIds",
									data.map(item => item.value)
								)
							}
							value={typologies?.data?.establishmentTypologies
								?.filter(item => values.typologyIds.includes(item.id))
								.map(item => ({ value: item.id, label: item.name }))}
							options={typologies?.data?.establishmentTypologies
								?.filter(item => values.categoryIds.includes(item.categoryId))
								.map(item => ({ value: item.id, label: item.name }))}
							className="basic-multi-select"
							classNamePrefix="select"
						/>
						{touched.typologyIds && errors.typologyIds && <div className={styles.error}>{errors.typologyIds}</div>}
						<Field
							name="country"
							id="country"
							label="Nazione"
							component={TextField}
							type="text"
							borderRadius="5px"
							disabled={!editEstablishmentFormOpen}
						/>
						<Field
							name="city"
							id="city"
							label="Città"
							component={TextField}
							type="text"
							borderRadius="5px"
							disabled={!editEstablishmentFormOpen}
						/>
						<Field
							name="street"
							id="street"
							label="Indirizzo"
							component={TextField}
							type="text"
							borderRadius="5px"
							disabled={!editEstablishmentFormOpen}
						/>
						<Field
							name="apartment"
							id="apartment"
							label="Appartamento"
							component={TextField}
							type="text"
							borderRadius="5px"
							disabled={!editEstablishmentFormOpen}
						/>
						<Field
							name="buildingNumber"
							id="buildingNumber"
							label="Civico"
							component={TextField}
							type="text"
							borderRadius="5px"
							disabled={!editEstablishmentFormOpen}
						/>
					</div>
					<div>
						<Field
							name="description"
							id="description"
							label="Descrizione"
							component={TextField}
							type="text"
							borderRadius="5px"
							disabled={!editEstablishmentFormOpen}
							textarea={true}
						/>
						<Field
							name="autocomplete"
							id="autocomplete"
							placeholder={"Inserisci indirizzo"}
							label="Cerca con Google"
							component={GoogleAutocomplete}
							disabled={!editEstablishmentFormOpen}
							setFieldValue={setFieldValue}
							type="text"
							borderRadius="5px"
						/>
					</div>
					<div>
						<Field
							name="email"
							id="email"
							label="Email"
							component={TextField}
							type="email"
							borderRadius="5px"
							disabled={!editEstablishmentFormOpen}
						/>
						<Field
							name="website"
							id="website"
							label="Website"
							component={TextField}
							type="text"
							borderRadius="5px"
							disabled={!editEstablishmentFormOpen}
						/>
						<Field
							name="phone"
							id="phone"
							label="N° telefonico"
							component={TextField}
							type="text"
							borderRadius="5px"
							disabled={!editEstablishmentFormOpen}
						/>
						<Field
							name="lat"
							id="lat"
							label="Lat"
							component={TextField}
							type="text"
							borderRadius="5px"
							disabled={!editEstablishmentFormOpen}
						/>
						<Field
							name="lng"
							id="lng"
							label="Lng"
							component={TextField}
							type="text"
							borderRadius="5px"
							disabled={!editEstablishmentFormOpen}
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
});

export default EditEstablishmentDetailsForm;
