import styles from "./DisabilitiesFacilitiesTable.module.scss";
import { useQuery } from "@apollo/client";
import { GET_DISABILITIES, GET_FACILITIES } from "../../../api/queries";
import Select, { components } from "react-select";
import React, { useState } from "react";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import PencilNeroIcon from "../../../../../assets/svg/pencil-nero.svg";
import PlusIcon from "../../../../../assets/svg/plus.svg";
import UploadDisabilityModal from "./containers/UploadDisabilityModal";
import DeleteDisabilityModal from "./containers/DeleteDisabilityModal";
import UploadFacilityModal from "./containers/UploadFacilityModal";
import DeleteFacilityModal from "./containers/DeleteFacilityModal";

const DisabilitiesFacilitiesTable = () => {
  const disabilities = useQuery(GET_DISABILITIES, {});
  const facilities = useQuery(GET_FACILITIES, {});
  const [uploadDisabilityModalOpen, setUploadDisabilityModalOpen] = useState(false);
  const [deleteDisabilityModalOpen, setDeleteDisabilityModalOpen] = useState(false);
  const [disabilityIdToDelete, setDisabilityIdToDelete] = useState(false);
  const [disabilityLabelToDelete, setDisabilityLabelToDelete] = useState("");
  const [facilityLabelToDelete, setFacilityLabelToDelete] = useState("");
  const [disabilityUrlToChange, setDisabilityUrlToChange] = useState("");
  const [facilityUrlToChange, setFacilityUrlToChange] = useState("");
  const [uploadFacilityModalOpen, setUploadFacilityModalOpen] = useState(false);
  const [deleteFacilityModalOpen, setDeleteFacilityModalOpen] = useState(false);
  const [facilityIdToDelete, setFacilityIdToDelete] = useState(false);

  const MultiValueRemove = props => {
    return (
      <components.MultiValueRemove {...props}>
        {<img width={"12px"} src={PencilNeroIcon} alt="Cambia icona"
          className="pencilEditIcon" />}
      </components.MultiValueRemove>
    );
  };

  return (
    <>
      <UploadDisabilityModal isModalOpen={uploadDisabilityModalOpen} setUploadDisabilityModalOpen={setUploadDisabilityModalOpen} />
      <DeleteDisabilityModal
        setDeleteDisabilityModalOpen={setDeleteDisabilityModalOpen}
        isModalOpen={deleteDisabilityModalOpen}
        disabilityIdToDelete={disabilityIdToDelete}
        disabilityLabelToDelete={disabilityLabelToDelete}
        disabilityUrlToChange={disabilityUrlToChange}
      />
      <UploadFacilityModal isModalOpen={uploadFacilityModalOpen} setUploadFacilityModalOpen={setUploadFacilityModalOpen} />
      <DeleteFacilityModal
        isModalOpen={deleteFacilityModalOpen}
        setDeleteFacilityModalOpen={setDeleteFacilityModalOpen}
        facilityIdToDelete={facilityIdToDelete}
        facilityLabelToDelete={facilityLabelToDelete}
        facilityUrlToChange={facilityUrlToChange}
      />
      <div className={styles.disabilitiesAndFacilities__wrapper}>
        <div className={styles.actions}>
          <label htmlFor={"disabilities"}>Disabilità</label>
          <PrimaryButton
            onClick={() => setUploadDisabilityModalOpen(true)}
            text="Aggiungi Disabilità"
            leftIconSrc={PlusIcon}
            fontSize="10px"
            height="25px"
            width="fit-content"
            padding="6px 10px"
            borderRadius="10px"
            background="#078EA6"
          />
        </div>
        <Select
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
            Menu: () => null,
            Input: () => null,
            MultiValueRemove,
          }}
          isMulti
          // isDisabled={!editEstablishmentFormOpen}
          name="disabilityIds"
          placeholder={"Seleziona disabilità"}
          value={disabilities?.data?.disabilities?.map(item => ({
            value: item.id,
            label: item.name,
            url: item.url,
          }))}
          onChange={(_, { removedValue }) => {
            if (removedValue.value) {
              setDisabilityIdToDelete(removedValue.value);
              setDisabilityLabelToDelete(removedValue.label);
              setDisabilityUrlToChange(removedValue.url);
              setDeleteDisabilityModalOpen(true);
            }
          }}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>

      <div className={styles.disabilitiesAndFacilities__wrapper}>
        <div className={styles.actions}>
          <label htmlFor={"facilities"}>Ausilio</label>
          <PrimaryButton
            onClick={() => setUploadFacilityModalOpen(true)}
            text="Aggiungi Ausilio"
            leftIconSrc={PlusIcon}
            fontSize="10px"
            height="25px"
            width="fit-content"
            padding="6px 10px"
            borderRadius="10px"
            background="#078EA6"
          />
        </div>
        <Select
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
            Menu: () => null,
            Input: () => null,
            MultiValueRemove,
          }}
          isMulti
          // isDisabled={!editEstablishmentFormOpen}
          name="facilityIds"
          placeholder={"Seleziona Categoria"}
          value={facilities?.data?.facilities?.map(item => ({
            value: item.id,
            label: item.name,
            url: item.url,
          }))}
          onChange={(_, { removedValue }) => {
            if (removedValue.value) {
              console.log(removedValue);
              setFacilityIdToDelete(removedValue.value);
              setFacilityLabelToDelete(removedValue.label);
              setFacilityUrlToChange(removedValue.url);
              setDeleteFacilityModalOpen(true);
            }
          }}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>
    </>
  );
};

export default DisabilitiesFacilitiesTable;
