import {
  Field, Form, Formik
 } from "formik";
import React, { useEffect } from "react";
import * as yup from "yup";
import { RoutePath } from "../../../../routers/constants";
import { useNavigate } from "react-router";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import TextField from "../../../Common/TextField";
import styles from "./SignIn.module.scss";
import { useMutation } from "@apollo/client";
import { SIGN_IN } from "../../api/mutations";
import Logo from "../../../../assets/images/logo.png";

export default () => {
  const initialValues = {
    email: "",
    password: "",
  };
  const [
    addTodo,
    {
      data,
      // loading,
      // error
    },
  ] = useMutation(SIGN_IN);

  const navigation = useNavigate();

  useEffect(() => {
    if (data?.signIn?.jwtToken) {
      localStorage.setItem("token", data.signIn.jwtToken);
      navigation(RoutePath.CLIENTS);
    }
  }, [data, navigation]);

  const validationSchema = {
    email: yup
      .string("Inserisci la tua email")
      .email("Inserisci un'email valida")
      .required("Email è obbligatoria"),
    password: yup
      .string()
      .min(3, "La password è troppo corta - deve essere di minimo 3 caratteri")
      .max(100, "La tua password è davvero grande!")
      .required("Password obbligatoria"),
  };
  return (
    <div className={styles.container__inner}>
      <div className={styles["login__container"]}>
        <img className={styles.logo} src={Logo} alt="logo" />
        <Formik
          initialValues={initialValues}
          validationSchema={yup.object().shape(validationSchema)}
          onSubmit={async ({ email, password }) => {
            await addTodo({
              variables: {
                signInInput: {
                  login: email,
                  password,
                },
              },
            });
          }}
        >
          {({ errors, touched }) => (
            <Form className={styles["login__form"]}>
              <Field
                name="email"
                id="email"
                label="Email"
                component={TextField}
                type="email"
                borderRadius="5px"
                inputColor="white"
              />
              <Field
                name="password"
                id="password"
                label="Password"
                component={TextField}
                type="password"
                borderRadius="5px"
                inputColor="white"
              />
              <PrimaryButton
                height="50px"
                width="100%"
                type="submit"
                outlined
                text="Accedi"
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
