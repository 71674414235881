import React from 'react';

import CrossIcon from '../../../../../../../../../assets/svg/close.svg';
import PlusIcon from '../../../../../../../../../assets/svg/plus.svg';
import { fileReaderToBase64 } from '../../../../../../../../../helpers';
import PrimaryButton from '../../../../../../../../Common/Buttons/PrimaryButton';
import Modal from '../../../../../../../../Common/Modal';
import { ADD_DETAILS, UPLOAD_IMAGE } from "../../../../../../../api/mutations";
import { GET_ESTABLISHMENT } from "../../../../../../../api/queries";

import styles from './UploadImageModal.module.scss';
import { useMutation } from "@apollo/client";
import {
	Field,
	Form,
	Formik
} from "formik";
import * as yup from "yup";
import TextField from "../../../../../../../../Common/TextField";
import Select from "react-select";

const UploadImageModal = ({
	isModalOpen,
	setUploadImageModalOpen,
	gallery,
	codeValues,
	id,
}) => {
	const regImg = /^data:image\/([\w+]+);base64,([\s\S]+)/;
	const initialValues = {
		detailsCode: '',
		detailsTitle: '',
		detailsDescription: '',
		detailsImageId: '',
		detailsImage: '',
		detailsImageBase64: '',
	};

	const validationSchema = {
		detailsCode: yup.string().required('Obbligatorio'),
		detailsTitle: yup.string().required('Obbligatorio'),
		detailsDescription: yup.string().required('Obbligatorio'),
		detailsImageBase64: yup.string().required('Obbligatorio'),
	};
	const [addDetailsImage] = useMutation(UPLOAD_IMAGE);
	const [editEstablishment] = useMutation(ADD_DETAILS, {
		refetchQueries: [{
			query: GET_ESTABLISHMENT,
			variables: {
				establishmentInput: {
					id: Number(id),
				}
			}
		}],
		awaitRefetchQueries: true,
	});

	return <Modal
		isModalOpen={isModalOpen}
		setIsModalOpen={() => {
			setUploadImageModalOpen(false);
		}}
	>
		<div className={styles['modal__upload-image']}>
			<Formik
				initialValues={initialValues}
				validationSchema={yup.object().shape(validationSchema)}
				onSubmit={async values => {
					const { data: { uploadPicture } } = await addDetailsImage({
						variables: {
							picture: values.detailsImage
						}
					});
					if (uploadPicture?.uploadPicture[0]?.id) {
						await editEstablishment({
							variables: {
								addDetailsInput: {
									establishmentId: id,
									details: {
										code: values.detailsCode,
										description: values.detailsDescription,
										title: values.detailsTitle,
										imageId: uploadPicture?.uploadPicture[0]?.id
									}
								}
							}
						});
						setUploadImageModalOpen(false);
					}
				}}
			>
				{({
					errors,
					touched,
					values,
					setFieldValue
				}) => {
					return (
						<Form>
							{values.detailsImageBase64 && values.detailsImageBase64?.match(regImg) &&
								<img src={values.detailsImageBase64} alt='house' className={styles['uploaded-image']} />
							}
							<div className={styles.select__wrap}>
								<label style={{
									display: "inline-block",
									marginBottom: '10px'
								}} htmlFor={"detailsCode"}>
									detailsCode
								</label>
								<Select
									name="detailsCode"
									placeholder="Seleziona Codice"
									onChange={data => setFieldValue("detailsCode", data.value)}
									options={codeValues.map(item => ({ value: item, label: item }))}
									className="basic-multi-select"
									classNamePrefix="select"
								/>
								{
									touched.detailsCode &&
									errors.detailsCode && <div className={styles.error}>{errors.detailsCode}</div>
								}
							</div>
							<Field name='detailsTitle' id='detailsTitle'
								label='titolo dettagli'
								component={TextField}
								type='text'
								borderRadius='5px'
								inputColor="white"
							/>
							<Field name='detailsDescription' id='detailsDescription'
								label='descrizione dettagli'
								component={TextField}
								type='text'
								borderRadius='5px'
								inputColor="white"
							/>
							<div className={styles['form-actions']}>
								<div className={styles['upload-img__btn']}>
									<label htmlFor="img" className={styles['btn__label']}>
										<img src={values.detailsImageBase64 ? CrossIcon : PlusIcon} alt="upload" />
										<span>Aggiungi Foto</span>
									</label>
									<input
										style={{
											width: '0.1px',
											height: '0.1px',
											opacity: '0',
											overflow: 'hidden',
											position: 'absolute',
											zIndex: '-1',
										}}
										onChange={async e => {
											e.persist();
											const img = Object.values(e.target.files);
											if (img?.length) {
												const base64ImageUrl = await fileReaderToBase64(
													img
												);

												setFieldValue("detailsImageBase64", base64ImageUrl);
												setFieldValue("detailsImage", img[0]);
											}
										}}
										name="img"
										id="img"
										type="file"
										accept="image/*"
									/>
								</div>
								{
									touched.detailsImageBase64 &&
									errors.detailsImageBase64 && <div className={styles.error}>{errors.detailsImageBase64}</div>
								}
								<PrimaryButton
									type='submit'
									fontSize='18px'
									text='Carica'
									width='100%'
								/>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	</Modal>;
};

export default UploadImageModal;
