import {
	useMemo, useState
} from 'react';

import DataTable from '../../../../Common/DataTable';
import { useQuery } from "@apollo/client";
import EstablishmentsDetailsPage from "../EstablishmentsDetailsPage";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import PlusIcon from "../../../../../assets/svg/plus.svg";
import UploadEstablishmentsModal from "../UploadEstablishmentsModal";
import { GET_ESTABLISHMENTS } from "../../../api/queries";


const EstablishmentsTable = ({ id }) => {
	const establishments = useQuery(GET_ESTABLISHMENTS, {
		variables: {
			establishmentListInput: {
				limit: 100000000,
				offset: 0
			}
		},
	});
	const [uploadEstablishmentsModalOpen, setUploadEstablishmentsModalOpen] = useState(false);

	let tableColumns = useMemo(() => [
		{
			name: 'name',
			label: 'Nome',
			filter: false,
			sort: true,
		},
		{
			name: 'category',
			label: 'Categoria',
			filter: false,
			sort: true,
		},
		{
			name: 'typology',
			label: 'Tipologia',
			filter: false,
			sort: true,
		},
		{
			name: 'phone',
			label: 'Contatto',
			filter: false,
			sort: true,
		},
		{
			name: 'city',
			label: 'Città',
			filter: false,
			sort: true,
		},
		{
			name: 'options',
			label: 'Opzioni',
			filter: false,
			sort: false,
			centered: true,
		}
	], []);

	return (
		<>
			{
				id ?
					<EstablishmentsDetailsPage
						id={id}
					/> :
					<>
						<UploadEstablishmentsModal
							setUploadEstablishmentsModalOpen={setUploadEstablishmentsModalOpen}
							isModalOpen={uploadEstablishmentsModalOpen}
						/>
						<div style={{
							marginBottom: "10px",
							display: "flex",
							width: '100%',
							justifyContent: "flex-end"
						}}>
							<PrimaryButton
								text='Aggiungi Attività'
								leftIconSrc={PlusIcon}
								onClick={() => setUploadEstablishmentsModalOpen(true)}
								fontSize="10px"
								height='25px'
								padding='6px 10px'
								borderRadius='10px'
								background='#078EA6'
							// color='#FC440F'
							/>
						</div>

						<DataTable
							tableTitle='Lista Stabilimenti'
							tableData={establishments?.data?.establishmentsList?.map(item => {
								return ({
									name: item.name,
									phone: item.phone,
									city: item?.address?.city,
									category: item.typologiesAndCategories.length ?
										item.typologiesAndCategories.reduce((acc, item) => acc.includes(
											item.category.name) ?
											acc :
											[...acc, item.category.name], []).join(" ") :
										"Vuoto",
									typology: item.typologiesAndCategories.length ?
										item.typologiesAndCategories.map(item => item.name).join(" ") :
										"Vuoto",
									id: item?.id
								});
							})}
							// tableName='Establishments'
							tableColumns={tableColumns}
						/>
					</>
			}
		</>
	);
};

export default EstablishmentsTable;
