import Collapsible from "react-collapsible";
import styles from "./CategoriesTypologies.module.scss";
import { useQuery } from "@apollo/client";
import { GET_CATEGORIES, GET_TYPOLOGIES } from "../../../api/queries";
import Select, { components } from "react-select";
import React, { useState } from "react";

import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import PlusIcon from "../../../../../assets/svg/plus.svg";
import PencilNeroIcon from "../../../../../assets/svg/pencil-nero.svg";
import UploadTypologyModal from "./containers/UploadTypologyModal";
import DeleteTypologyModal from "./containers/DeleteTypologyModal";
import UploadCategoryModal from "./containers/UploadCategoryModal";
import DeleteCategoryModal from "./containers/DeleteCategoryModal";

const CategoriesTypologiesTable = () => {
  const categories = useQuery(GET_CATEGORIES, {});
  const typologies = useQuery(GET_TYPOLOGIES, {});
  const [uploadTypologyModalOpen, setUploadTypologyModalOpen] = useState(false);
  const [uploadCategoryModalOpen, setUploadCategoryModalOpen] = useState(false);
  const [deleteCategoryModalOpen, setDeleteCategoryModalOpen] = useState(false);
  const [categoryLabelToDelete, setCategoryLabelToDelete] = useState("");
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(false);
  const [categoryUrlToChange, setCategoryUrlToChange] = useState("");
  const [deleteTypologyModalOpen, setDeleteTypologyModalOpen] = useState(false);
  const [typologyIdToDelete, setTypologyIdToDelete] = useState(false);

  const MultiValueRemove = props => {
    return (
      <components.MultiValueRemove {...props}>
        {
          <img
            width={"12px"}
            color={"#303030"}
            sx={{ color: "#303030" }}
            onClick={() => setDeleteCategoryModalOpen(true)}
            src={PencilNeroIcon}
            alt="Cambia icona"
          />
        }
      </components.MultiValueRemove>
    );
  };
  return (
    <>
      <UploadTypologyModal
        isModalOpen={uploadTypologyModalOpen}
        setUploadTypologyModalOpen={setUploadTypologyModalOpen}
        categories={categories?.data?.establishmentCategories}
      />
      <UploadCategoryModal isModalOpen={uploadCategoryModalOpen} setUploadTypologyModalOpen={setUploadCategoryModalOpen} />
      <DeleteTypologyModal
        setDeleteTypologyModalOpen={setDeleteTypologyModalOpen}
        isModalOpen={deleteTypologyModalOpen}
        typologyIdToDelete={typologyIdToDelete}
      />
      <DeleteCategoryModal
        setDeleteCategoryModalOpen={setDeleteCategoryModalOpen}
        isModalOpen={deleteCategoryModalOpen}
        categoryIdToDelete={categoryIdToDelete}
        categoryLabelToDelete={categoryLabelToDelete}
        categoryUrlToChange={categoryUrlToChange}
      />
      <div className={styles.categoriesAndTypologies__wrapper}>
        <div className={styles.actions}>
          <label htmlFor={"categories"}>Categorie</label>
          <PrimaryButton
            onClick={() => setUploadCategoryModalOpen(true)}
            text="Crea Categoria"
            leftIconSrc={PlusIcon}
            fontSize="10px"
            height="25px"
            width="fit-content"
            padding="6px 10px"
            borderRadius="10px"
            background="#078EA6"
          />
        </div>
        <Select
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
            Menu: () => null,
            Input: () => null,
            MultiValueRemove,
          }}
          isMulti
          // isDisabled={!editEstablishmentFormOpen}
          name="categoryIds"
          placeholder={"Seleziona Categoria"}
          value={categories?.data?.establishmentCategories?.map(item => ({
            value: item.id,
            label: item.name,
            url: item.url,
          }))}
          onChange={(_, { removedValue }) => {
            if (removedValue.value) {
              setCategoryIdToDelete(removedValue.value);
              setCategoryLabelToDelete(removedValue.label);
              setCategoryUrlToChange(removedValue.url);
            }
          }}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>
      <div className={styles.categoriesAndTypologies__wrapper}>
        <div className={styles.actions}>
          <label htmlFor={"categories"}>Tipologie</label>
          <PrimaryButton
            onClick={() => setUploadTypologyModalOpen(true)}
            text="Crea Tipologia"
            leftIconSrc={PlusIcon}
            fontSize="10px"
            height="25px"
            width="fit-content"
            padding="6px 10px"
            borderRadius="10px"
            background="#078EA6"
          />
        </div>

        {categories?.data?.establishmentCategories?.map(category => {
          return (
            <Collapsible key={category.id} tabIndex={category.id} trigger={category.name}>
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null,
                  Menu: () => null,
                  Input: () => null,
                }}
                isMulti
                // isDisabled={!editEstablishmentFormOpen}
                name="categoryIds"
                placeholder={"Seleziona categorie"}
                value={typologies?.data?.establishmentTypologies
                  ?.filter(typology => Number(typology.categoryId) === Number(category.id))
                  .map(item => ({
                    value: item.id,
                    label: item.name,
                  }))}
                onChange={(_, { removedValue }) => {
                  if (removedValue.value) {
                    setTypologyIdToDelete(removedValue.value);
                    setDeleteTypologyModalOpen(true);
                  }
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Collapsible>
          );
        })}
      </div>
    </>
  );
};

export default CategoriesTypologiesTable;
