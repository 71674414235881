import UserProfile from "./containers/UserProfile";
import styles from "./UserDetailsPage.module.scss";
import { useQuery } from "@apollo/client";
import DataTable from "../../../../Common/DataTable";
import { useMemo, useState } from "react";
import { RoutePath } from "../../../../../routers/constants";
import { GET_USER } from "../../../api/queries";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import BinIcon from "../../../../../assets/svg/bin.svg";
import DeleteUserModal from "./containers/DeleteUserModal";

export default ({ id }) => {
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const { data } = useQuery(GET_USER, {
    variables: {
      userId: Number(id),
    },
  });

  //   let tableColumns = useMemo(
  //     () => [
  //       {
  //         name: "name",
  //         label: "Nome",
  //         filter: false,
  //         sort: true,
  //       },
  //       {
  //         name: "category",
  //         label: "Categoria",
  //         filter: false,
  //         sort: true,
  //       },
  //       {
  //         name: "typology",
  //         label: "Tipologia",
  //         filter: false,
  //         sort: true,
  //       },

  //       {
  //         name: "phone",
  //         label: "Contatto",
  //         filter: false,
  //         sort: true,
  //       },
  //       {
  //         name: "city",
  //         label: "Città",
  //         filter: false,
  //         sort: true,
  //       },
  //       {
  //         name: "options",
  //         label: "Opzioni",
  //         filter: false,
  //         sort: false,
  //         centered: true,
  //       },
  //     ],
  //     []
  //   );

  return (
    <>
      {data?.getUser ? (
        <div className={styles.container__inner}>
          <UserProfile user={data.getUser} />
          {/* <DataTable
						tableTitle="Preferiti del cliente"
						detailsLink={RoutePath.ESTABLISHMENTS}
						tableData={data?.getUser?.favoriteList?.map(item => {
							return {
								name: item.name,
								phone: item.phone,
								city: item?.address?.city,
								category: item.typologiesAndCategories.length ?
									item.typologiesAndCategories
										.reduce((acc, item) => (acc.includes(item.category.name) ? acc : [...acc, item.category.name]), [])
										.join(" ") :
									"Empty",
								typology: item.typologiesAndCategories.length ?
									item.typologiesAndCategories.map(item => item.name).join(" ") : "Empty",
								id: item?.id,
							};
						})}
						// tableName='Establishments'
						tableColumns={tableColumns}
					/> */}
          <div className={styles["delete-btn__wrapper"]}>
            <PrimaryButton
              width="auto"
              border="2px solid #FC440F"
              text="Elimina Cliente"
              color="#FC440F"
              padding="8px"
              fontSize="18px"
              background="transparent"
              leftIconSrc={BinIcon}
              onClick={() => setIsConfirmDeleteModalOpen(true)}
            />
          </div>
          <DeleteUserModal
            setDeleteUserModalOpen={setIsConfirmDeleteModalOpen}
            isModalOpen={isConfirmDeleteModalOpen}
            userIdToDelete={id}
          />
        </div>
      ) : null}
    </>
  );
};
