import { gql } from "@apollo/client";

export const EDIT_USER = gql`
  mutation editUserByAdmin($editUserByAdminInput: EditUserByAdminInput!) {
    editUserByAdmin(editUserByAdminInput: $editUserByAdminInput) {
      id
      firstName
      lastName
      email
      dateBirth
      phone
      role
      avatar {
        id
        url
      }
      createdAt
      updatedAt
      isActive
      address {
        id
        country
        city
        street
        buildingNumber
        apartment
      }
      disabilities {
        id
        name
        isDisabled
      }
      facilities {
        id
        name
        isDisabled
      }
    }
  }
`;

export const ADD_ESTABLISHMENTS = gql`
  mutation createEstablishment($establishmentInput: CreateEstablishmentInput!) {
    createEstablishment(establishmentInput: $establishmentInput) {
      id
      success
    }
  }
`;

export const EDIT_ESTABLISHMENT = gql`
  mutation editEstablishment($editEstablishmentInput: EditEstablishmentInput!) {
    editEstablishment(editEstablishmentInput: $editEstablishmentInput) {
      id
      name
      phone
      email
      website
      description
      details {
        id
        code
        description
        image {
          id
          url
        }
      }
      address {
        id
        country
        city
        street
        buildingNumber
        apartment
      }
      typologiesAndCategories {
        typologyId
        name
        category {
          id
          name
          isDisabled
        }
        isDisabled
      }
      disabilities {
        id
        name
        isDisabled
      }
      facilities {
        id
        name
        isDisabled
      }
      isFavorite
      cover {
        id
        url
      }
      gallery {
        id
        url
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation uploadPicture($picture: [Upload!]!) {
    uploadPicture(picture: $picture) {
      uploadPicture {
        id
        url
      }
    }
  }
`;

export const CREATE_TYPOLOGY = gql`
  mutation createTypology($createTypologyInput: CreateTypologyInput!) {
    createTypology(createTypologyInput: $createTypologyInput) {
      success
    }
  }
`;
export const CREATE_TAG = gql`
  mutation CreateTag($createTagInput: CreateTagInput!) {
    createTag(CreateTagInput: $createTagInput) {
      success
    }
  }
`;
export const CREATE_TROVERA = gql`
  mutation CreateTrovera($createTroveraInput: CreateTroveraInput!) {
    createTrovera(CreateTroveraInput: $createTroveraInput) {
      success
    }
  }
`;

export const CREATE_TAG_ESTABLISHMENT = gql`
  mutation CreateTagEstablishment($createTagEstablishmentInput: CreateTagEstablishmentInput!) {
    createTagEstablishment(CreateTagEstablishmentInput: $createTagEstablishmentInput) {
      success
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory($name: String!) {
    createCategory(name: $name) {
      success
    }
  }
`;

export const CREATE_DISABILITY = gql`
  mutation createDisability($name: String!) {
    createDisability(name: $name) {
      success
    }
  }
`;

export const CREATE_FACILITY = gql`
  mutation createFacility($name: String!) {
    createFacility(name: $name) {
      success
    }
  }
`;

export const DELETE_TYPOLOGY = gql`
  mutation disableTypology($typologyId: Int!) {
    disableTypology(typologyId: $typologyId) {
      success
    }
  }
`;
export const DELETE_TAG_ESTABLISHMENT = gql`
  mutation DeleteTagEstablishment($deleteTagEstablishmentInput: Int!) {
    deleteTagEstablishment(DeleteTagEstablishmentInput: $deleteTagEstablishmentInput) {
      success
    }
  }
`;
export const DELETE_TAG = gql`
  mutation DeleteTag($deleteTagInput: Int!) {
    deleteTag(DeleteTagInput: $deleteTagInput) {
      success
    }
  }
`;
export const DISABLE_ESTABLISHMENT = gql`
  mutation disableEstablishment($establishmentId: Int!) {
    disableEstablishment(establishmentId: $establishmentId) {
      success
    }
  }
`;
export const DISABLE_USER = gql`
  mutation disableUser($userId: Int!) {
    disableUser(userId: $userId) {
      success
    }
  }
`;
export const DELETE_TROVERA_ESTABLISHMENT = gql`
  mutation DeleteTroveraEstablishment($deleteTroveraEstablishmentInput: Int!) {
    deleteTroveraEstablishment(DeleteTroveraEstablishmentInput: $deleteTroveraEstablishmentInput) {
      success
    }
  }
`;
export const DELETE_TROVERA = gql`
  mutation DeleteTrovera($deleteTroveraInput: Int!) {
    deleteTrovera(DeleteTroveraInput: $deleteTroveraInput) {
      success
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation disableCategory($categoryId: Int!) {
    disableCategory(categoryId: $categoryId) {
      success
    }
  }
`;

export const DELETE_DISABILITY = gql`
  mutation disableDisability($disabilityId: Int!) {
    disableDisability(disabilityId: $disabilityId) {
      success
    }
  }
`;

export const DELETE_FACILITY = gql`
  mutation disableFacility($facilityId: Int!) {
    disableFacility(facilityId: $facilityId) {
      success
    }
  }
`;

export const ADD_PICTURE_TO_ESTABLISHMENT = gql`
  mutation addPicturesToEstablishmentGallery($addPicturesToEstablishmentGalleryInput: AddPicturesToEstablishmentGalleryInput!) {
    addPicturesToEstablishmentGallery(addPicturesToEstablishmentGalleryInput: $addPicturesToEstablishmentGalleryInput) {
      success
    }
  }
`;

export const DELETE_PICTURE_FROM_ESTABLISHMENT = gql`
  mutation dropPictureFromEstablishmentGallery($dropEstablishmentPictureInput: DropEstablishmentPictureInput!) {
    dropPictureFromEstablishmentGallery(dropEstablishmentPictureInput: $dropEstablishmentPictureInput) {
      success
    }
  }
`;

export const EDIT_ESTABLISHMENT_PICTURE = gql`
  mutation editEstablishmentGalleryPicture($editEstablishmentPictureInput: EditEstablishmentPictureInput!) {
    editEstablishmentGalleryPicture(editEstablishmentPictureInput: $editEstablishmentPictureInput) {
      success
    }
  }
`;

export const EDIT_CATEGORY_IMAGE = gql`
  mutation editCategory($editCategoryInput: EditCategoryInput!) {
    editCategory(editCategoryInput: $editCategoryInput) {
      id
      name
      isDisabled
    }
  }
`;
export const EDIT_DISABILITY_IMAGE = gql`
  mutation editDisability($editDisabilityInput: EditDisabilityInput!) {
    editDisability(editDisabilityInput: $editDisabilityInput) {
      id
      name
      url
      isDisabled
    }
  }
`;
export const EDIT_FACILITY_IMAGE = gql`
  mutation editFacility($editFacilityInput: EditFacilityInput!) {
    editFacility(editFacilityInput: $editFacilityInput) {
      id
      name
      isDisabled
    }
  }
`;

export const ADD_DETAILS = gql`
  mutation addEstablishmentDetails($addDetailsInput: AddDetailsInput!) {
    addEstablishmentDetails(addDetailsInput: $addDetailsInput) {
      success
    }
  }
`;

export const EDIT_DETAILS = gql`
  mutation dropEstablishmentDetail($dropDetailInput: DropDetailInput!) {
    dropEstablishmentDetail(dropDetailInput: $dropDetailInput) {
      success
    }
  }
`;
export const EDIT_TAGS_TO_ESTABLISHMENT = gql`
  mutation EditTagEstablishment($editTagEstablishmentInput: EditTagEstablishmentInput!) {
    editTagEstablishment(EditTagEstablishmentInput: $editTagEstablishmentInput) {
      success
    }
  }
`;

export const EDIT_TROVERA_TO_ESTABLISHMENT = gql`
  mutation EditTrovera($editTroveraInput: EditTroveraInput!) {
    editTrovera(EditTroveraInput: $editTroveraInput) {
      success
    }
  }
`;

export const ADD_COVER_PICTURE = gql`
  mutation addCoverPicture($addCoverPictureInput: AddCoverPictureInput!) {
    addCoverPicture(addCoverPictureInput: $addCoverPictureInput) {
      success
    }
  }
`;
