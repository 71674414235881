import React, { useState, useRef } from "react";
import Modal from "../../../../../../Common/Modal";
import { useMutation } from "@apollo/client";
import {
 DELETE_CATEGORY, UPLOAD_IMAGE, EDIT_CATEGORY_IMAGE
} from "../../../../../api/mutations";
import { GET_CATEGORIES } from "../../../../../api/queries";
//import ConfirmModal from "../../../../../../Common/ConfirmModal";
import PrimaryButton from "../../../../../../Common/Buttons/PrimaryButton";
import styles from "./editmodal.module.scss";
import { Form, Formik } from "formik";
import { fileReaderToBase64 } from "../../../../../../../helpers";

const DeleteTypologyModal = ({
  isModalOpen,
  setDeleteCategoryModalOpen,
  categoryIdToDelete,
  categoryLabelToDelete,
  categoryUrlToChange,
}) => {
  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    refetchQueries: [GET_CATEGORIES],
    awaitRefetchQueries: true,
  });

  const [editCategory] = useMutation(EDIT_CATEGORY_IMAGE, {
    refetchQueries: [GET_CATEGORIES],
    awaitRefetchQueries: true,
  });

  const initialValues = {
    detailsImageId: "",
    detailsImage: "",
  };

  const formRef = useRef();
  const [addDetailsImage] = useMutation(UPLOAD_IMAGE);

  const [newImg, setNewImg] = useState("");

  // const uploadIcon = base64 => {
  //   console.log("cose per caricare", base64);
  //   //setDeleteCategoryModalOpen(false);
  // };

  const confirmModal = async () => {
    await deleteCategory({
      variables: {
        categoryId: categoryIdToDelete,
      },
    });
    setDeleteCategoryModalOpen(false);
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={() => {
        setDeleteCategoryModalOpen(false);
      }}
    >
      <h2 className={styles["confirm-modal__title"]}>Modifica Categoria</h2>
      <div className={styles["uploadSection"]}>
        {/* {haveImg && (
          <div className={styles["uploadSection-img"]}>
            <img src={""} alt={"Upload"} />
          </div>
        )} */}

        <Formik
          initialValues={initialValues}
          innerRef={formRef}
          //validationSchema={yup.object().shape(validationSchema)}
          onSubmit={async values => {
            const {
              data: { uploadPicture },
            } = await addDetailsImage({
              variables: {
                picture: values.detailsImage,
              },
            });
            console.log("aaaaaaaaaa ho caricato", uploadPicture?.uploadPicture[0]?.id);

            if (uploadPicture?.uploadPicture[0]?.id) {
              await editCategory({
                variables: {
                  editCategoryInput: {
                    id: categoryIdToDelete,
                    name: categoryLabelToDelete,
                    image_id: uploadPicture?.uploadPicture[0]?.id,
                  },
                },
              });
              await setDeleteCategoryModalOpen(false);
            }
          }}
        >
          {({ /* errors, touched, values, */ setFieldValue }) => {
            return (
              <Form>
                <div className={styles.select__wrap}>
                  <div className={styles["form-actions"]}>
                    {categoryUrlToChange ? (
                      <object data={categoryUrlToChange} type="image/svg+xml">
                        <img src={categoryUrlToChange} alt="category" width={"150px"} />
                      </object>
                    ) : null}
                    <div className={styles["upload-img__btn"]}>
                      <label htmlFor="img" className={styles["btn__label"]}>
                        <span>Cambia foto</span>
                      </label>
                      <input
                        style={{
                          width: "0.1px",
                          height: "0.1px",
                          opacity: "0",
                          overflow: "hidden",
                          position: "absolute",
                          zIndex: "-1",
                        }}
                        onChange={async e => {
                          e.persist();
                          const img = Object.values(e.target.files);
                          if (img?.length) {
                            const base64ImageUrl = await fileReaderToBase64(img);
                            setNewImg(base64ImageUrl);
                            // setDeleteCategoryModalOpen(false);
                            //setFieldValue("detailsImageBase64", base64ImageUrl);
                            setFieldValue("detailsImage", img[0]);
                            formRef.current.handleSubmit();
                          }
                        }}
                        name="img"
                        id="img"
                        type="file"
                        accept=".svg"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      <h4 className={styles["confirm-modal__title"]}>Vuoi eliminare la categoria selezionata?</h4>
      <div className={styles["confirm-modal__actions"]}>
        <PrimaryButton text="Si" fontSize="18px" onClick={confirmModal}
height="25px" width="80px" padding="15px 20px"
borderRadius="5px" />
        <PrimaryButton
          text="No"
          fontSize="18px"
          onClick={() => setDeleteCategoryModalOpen(false)}
          height="25px"
          width="80px"
          padding="15px 20px"
          borderRadius="5px"
          background="#FC440F"
        />
      </div>
    </Modal>
  );
};

export default DeleteTypologyModal;
